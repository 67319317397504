



















































































































































































































































































































































































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import DatePicker from "@/components/common/Inputs/DatePicker.vue";
import ImageUpload from "@/components/common/Upload/Image.vue";
import { Progress } from "@/Enum/Progress";
import { IRSelectKPIs, IRCampaignType, CampaignType, CampaignFrequency,CampaignWeek,ProductBasisValuePair,CampaignBasis, CampaignApplicable} from "@/Enum/Campaign";
import { IBasicInfo, ICampaign } from "@/interfaces/Campaign";
import Snackbar from '@/components/common/Snackbar.vue';
import CompanySettingService from "@/services/CompanySettingServics";
import CampaignService from "@/services/CampaignService";
import moment from 'moment'
import { faL } from "@fortawesome/free-solid-svg-icons";

@Component({
  components: { DatePicker, ImageUpload,Snackbar },
})
export default class Step1 extends Vue{
  @Prop() readonly createCampaign!: string;
  @Prop() readonly editCampaign!: string;
  @Prop() readonly campid!: any;
  @Prop() readonly Campaign!: ICampaign;
  @Prop({
        type: Object, // Specify the type of the prop
        default: () => ({
          campaignType:0,
          irkpIs:[]
         })
    })  readonly CampaignBasicInfo!:any;
  @Prop() readonly CampaignConfig!:any;
  @Prop() readonly CampaignTaskDetails!:any;
   
  private BasicInfo:any={}
  private s_dt:string=""; //2022-12-01
  private testDate="2023-01-19";
  private e_dt:string="";
  private todayplusone: string = "";
  private startDate: string = "";
  private endDate: string = "";
  private IRDisplayTypeItems: any = []
  private campaignType: any = [
    { key: "Product Availability", value: CampaignType.SKU },
    // { key: "Availability", value: CampaignType.Availability },
    { key: "Planogram Compliance", value: CampaignType.Planogram_Compliance },
    { key: "Shelf Share", value: CampaignType.Shelf_Share },
    { key: "Open Campaign", value: CampaignType.Other },
    { key:"Promo Audit", value:CampaignType.Promo},
    { key:"Competitor Survey", value: CampaignType.Competitor_Survey},
    { key: "IR Campaign", value: CampaignType.IR_Campaign}
  ]
  private selectKPIs: any = [
    { key: "Product Availability", value: IRSelectKPIs.ProductAvailability },
    { key: "SOS", value: IRSelectKPIs.SOS },
  ]
  private TaskFrequency: any=[
    { key: "Every Visit", value: CampaignFrequency.EveryVisit },
    { key: "Daily", value: CampaignFrequency.Daily},
    { key: "Defined Day(s) of the week", value: CampaignFrequency.DefinedDayOfWeek},

  ]
  ;
  private campaignProductBasis : any = [
    { key:"Primary Category",value: CampaignBasis.PC },
    { key:"Secondary Category",value: CampaignBasis.SC },
    { key:"Products",value: CampaignBasis.Product }
  ];
  private irCampaignType : any = [
    { key:"FA IR",value: IRCampaignType.FAIR },
    { key:"Partner IR",value: IRCampaignType.PartnerIR }
  ];
  private campaigntemp : any = [
    { key:"Primary Category",value: CampaignBasis.PC },
    { key:"Secondary Category",value: CampaignBasis.SC },
    { key:"Products",value: CampaignBasis.Product },
    { key:"Shop",value: CampaignBasis.Outlet }
  ];
  private campaignApplicable: any=[
    {key:"Promoter (ISR)", value:CampaignApplicable.ISR},
    {key:"Merchandiser", value:CampaignApplicable.Merchandiser},
    {key:"SR", value:CampaignApplicable.SR}
  ]

  private WeekDays=[
    {key:"Sun",value : CampaignWeek.Sun},
    {key:"Mon",value : CampaignWeek.Mon}, 
    {key:"Tue",value : CampaignWeek.Tue},
    {key:"Wed",value : CampaignWeek.Wed},
    {key:"Thu",value : CampaignWeek.Thur},
    {key:"Fri",value : CampaignWeek.Fri},
    {key:"Sat",value : CampaignWeek.Sat},
  ]
  
  public getCompanySettingsService:boolean = false;
  private maxDaysFromStartDate: number = 180;
  private productBasis: any = '';
  private employeeBasis: string='';
  //   'SKU': CampaignType.SKU,
  //   'Availability': CampaignType.Availability,
  //   'Planogram Compliance': CampaignType.Planogram_Compliance,
  //   'Shelf Share': CampaignType.Shelf_Share,
  //   'Other': CampaignType.Other,
  // };
  private valid: boolean = false;
  private basicInfo: IBasicInfo = {
    Name:"",
    Description: "",
    StartDate: "",
    EndDate: "",
    CampaignType: CampaignType.SKU,
    IRCampaignType: IRCampaignType.FAIR,
    IRCampaignIdentifier: "",
    IRKPIs: [], 
    IRDisplayTypes : [],
    CampaignBasis: CampaignBasis.PC,
    IRProductAvailabilityBasis:CampaignBasis.PC,
    IRSOSBasis:CampaignBasis.PC,
    CampaignEmployeeType:[],
    BannerImageGuid: "",
    ContractLink: "",
    CampaignFrequency: CampaignFrequency.EveryVisit,
    CampaignWeekDays: [],
    RequiredForEndDay: false,
    IsStockOutAlertsEnabled  : false,
    IsSosTargetBreachAlertEnabled: false,
    IsMBQBreachAlertEnabled  : false,
    IsAllProductBasisRequired: false,
    IsCampaignSaveAsDraft : false,
    UsesImageRecognition : false
  };

  private snackBarMessage: string = "";
  private snackbar: boolean = false;
  private showIRProductAvalabilityBasis: boolean = false;
  private showIRSOSBasis: boolean = false;
  
  @Watch('basicInfo.IRKPIs')
    onIRKPIsChanged(newValue: IRSelectKPIs[], oldValue: IRSelectKPIs[]) {
        if (newValue.includes(IRSelectKPIs.ProductAvailability)) {
            this.showIRProductAvalabilityBasis = true;
        } else {
          this.showIRProductAvalabilityBasis = false;
        }
        if (newValue.includes(IRSelectKPIs.SOS)) {
            this.showIRSOSBasis = true;
        } else {
          this.showIRSOSBasis = false;
        }
    }

  closeSnackbar(value: boolean) {
    this.snackbar = value;
  }

  public ValidateForm() {
    var startDate = Date.parse(this.basicInfo.StartDate);
    var endDate = Date.parse(this.basicInfo.EndDate);
    if(startDate>endDate)
    {
        this.snackBarMessage = "StartDate can't be greater than EndDate.";
        this.snackbar = true;
        return false;
    }

    this.ProductBasis();
    if(this.basicInfo.CampaignType == 6 || this.CampaignBasicInfo.campaignType == 6)
      this.EmitIRDisplayTypeItems();
    // @ts-ignore
    return this.$refs.form.validate();
  }

  public GetStepData(){
    if(this.createCampaign=='true')
      return this.basicInfo;
    if(this.editCampaign=='true')
    {
      this.basicInfo.EndDate=this.e_dt;
      return this.basicInfo;
    }  
  }

  get maxEndDate(): string {
    var date = new Date(this.basicInfo.StartDate);
    date.setDate(date.getDate() + this.maxDaysFromStartDate);
    return date.toISOString().split("T")[0];
  }

  @Emit("ProductBasis")
  ProductBasis(){
    return this.basicInfo.CampaignBasis;   //return this.productBasis;  
  }
  @Emit("EmployeeBasis")
  EmployeeBasis(){
    return this.employeeBasis;
  }
  @Emit("EmitIRDisplayTypeItems")
  EmitIRDisplayTypeItems()
  {
    return this.IRDisplayTypeItems;
  }

  created() {
    var date = new Date();
    date.setDate(date.getDate());
    this.todayplusone = date.toISOString().split("T")[0];
    this.basicInfo.StartDate = this.todayplusone;
    date.setDate(date.getDate() + 6);
    this.basicInfo.EndDate = date.toISOString().split("T")[0];
  }

  campaignTypeChanged(changedValue:any)
  {
    if(changedValue==CampaignType.Other)
    { 
      this.campaignProductBasis= this.campaigntemp;
      if(this.productBasis==CampaignBasis.Outlet)
      {
        this.productBasis = CampaignBasis.PC;
      }
    }
    else if(changedValue==CampaignType.Competitor_Survey)
    {
       this.campaignProductBasis = this.campaigntemp.
        filter((s :any) =>s.value==CampaignBasis.PC);
    }
    else
    {
      this.campaignProductBasis = this.campaigntemp.
        filter((s :any) =>s.value!=CampaignBasis.Outlet);
    }
    
  
  }
  // DisableCampBasisChip(item:any){
  //   if(this.basicInfo.CampaignType=this.campaignProductBasis.Outlet)
  //   {
  //     if(item.value==CampaignBasis.Outlet)
  //     {
  //       return true;
  //     }
  //   }

  //   return false;
  // }

  // public closeEndDateMenu(){
  //   // @ts-ignore
  //   this.$refs['endDate'].closeMenu();
  // }
private loader:boolean=false;
  get startDt()
    {
        return moment(this.CampaignBasicInfo.startDate).format('ddd, DD-MMM-YYYY');
    }

  async mounted() {
    const apiDataForDisplayType = await  CampaignService.GetDisplayTypesForIRCampaign()
    this.IRDisplayTypeItems = apiDataForDisplayType.data.map((item: { name: any; displayEnum: any; }) => {
      return { key: item.name, value: item.displayEnum };
  });
  const getResult = await CompanySettingService.companyUsesImageRecognition()
  this.getCompanySettingsService = getResult.data.result;  

  const res = await CampaignService.getCampaignInfo(this.$route.params.ID)

  this.BasicInfo=res.data.campaignModel.basicInfo;
  
  var dt = this.BasicInfo.startDate;
  this.s_dt =  dt.split("T")[0]; 
  //this.s_dt = moment(this.s_dt).format('ddd, DD-MMM-YYYY').toString();
  
  var dt1 = this.BasicInfo.endDate;
  this.e_dt =  dt1.split("T")[0];
  if(this.editCampaign){
  setTimeout(()=> this.loader=true,1000)
  }
  else{
    this.loader=true;
  }
  
  }
}
