

















































































































































































































































































































































































































































































































































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import DatePicker from "@/components/common/Inputs/DatePicker.vue";
//import TextField from "@/components/common/Inputs/TextField.vue";
//import { Progress } from "@/Enum/Progress";
import { CampaignType, CampaignFrequency } from "@/Enum/Campaign";
import { IBasicInfo, ICampaign } from "@/interfaces/Campaign";
// import Step1 from "@/components/Campaign/New/Steps/Step1.vue";
//import Step2 from "@/components/Campaign/New/Steps/Step2.vue";
//import RegionStep4 from "@/components/common/containers/RegionStep4.vue";
import ListViewContainer from "@/components/common/containers/ListViewContainer.vue";
//import ShopContainer from "@/components/common/containers/ShopContainer.vue";
import { ICampaignConfig } from "@/interfaces/Campaign";
import { ProductBasisValuePair } from "@/Enum/Campaign";
import { CampaignWeek, CampaignApplicable, IRCampaignType, IRSelectKPIs } from "@/Enum/Campaign";
//import CampaignBasisSelection from "@/components/Campaign/New/Helpers/Product/CampaignBasisSelection.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import ImageUpload from "@/components/common/Upload/Image.vue";
import CampaignService from "@/services/CampaignService";
import ObjectView  from '@/components/Campaign/View/ObjectView.vue'
import CompanySettingService from "@/services/CompanySettingServics";
import moment from 'moment';
//  Vue.prototype.moment = moment
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import GeographyService from "@/services/GeographyService";
import router from "@/router/routes";

@Component({
  components: {
    DatePicker,
    ListViewContainer,
    Snackbar,
    ValidationProvider,
    ValidationObserver,
    ImageUpload,
    ObjectView,
  },
})

export default class Step4 extends Vue {
  @Prop() readonly Campaign!: ICampaign;
  @Prop() readonly step2DisplayData!: any;
  // @Prop() readonly EmployeeBasis!: string;
  @Prop() readonly step3SurveyString!: string;
  @Prop() readonly createCampaign!: string;
  @Prop() readonly editCampaign!: string;
  @Prop() readonly campid!: any;
  @Prop() readonly viewCampaignInfo!:any;
  @Prop() readonly CampaignBasicInfo!:any;
  @Prop() readonly CampaignTaskDetails!:any;
  @Prop() readonly CampaignConfig!:any;
  @Prop() readonly IRDisplayTypeItems :any;

  
  public getCompanySettingsService:boolean = false;
  // private todayplusone: string = "";
  // private startDate: string = "";
  // private endDate: string = "";
  private campaignType: any = [
    { key: "Product Availability", value: CampaignType.SKU },
    //{ key: "Availability", value: CampaignType.Availability },
    { key: "Planogram Compliance", value: CampaignType.Planogram_Compliance },
    { key: "Shelf Share", value: CampaignType.Shelf_Share },
    { key: "Open Campaign", value: CampaignType.Other },
    { key:"Promo Audit", value: CampaignType.Promo},
    { key: "Competitor Survey", value: CampaignType.Competitor_Survey},
    { key: "IR Campaign", value: CampaignType.IR_Campaign},
  ];
  private irCampaignType : any = [
    { key:"FA IR",value: IRCampaignType.FAIR },
    { key:"Partner IR",value: IRCampaignType.PartnerIR }
  ];
  private selectKPIs: any = [
    { key: "Product Availability", value: IRSelectKPIs.ProductAvailability },
    { key: "SOS", value: IRSelectKPIs.SOS },
  ]
  private TaskFrequency: any = [
    { key: "Every Visit", value: CampaignFrequency.EveryVisit },
    { key: "Daily", value: CampaignFrequency.Daily },
    {
      key: "Once every month, anyday",
      value: CampaignFrequency.OnceEveryMonth,
    },
    {
      key: "Defined Day(s) of the week",
      value: CampaignFrequency.DefinedDayOfWeek,
    },
  ];
    private campaignApplicable: any=[
    {key:"Promotor(ISR)", value:CampaignApplicable.ISR},
    {key:"Merchandiser", value:CampaignApplicable.Merchandiser},
    {key:"SR", value:CampaignApplicable.SR}
  ]

  private WeekDays = [
    { key: "Sun", value: CampaignWeek.Sun },
    { key: "Mon", value: CampaignWeek.Mon },
    { key: "Tue", value: CampaignWeek.Tue },
    { key: "Wed", value: CampaignWeek.Wed },
    { key: "Thu", value: CampaignWeek.Thur },
    { key: "Fri", value: CampaignWeek.Fri },
    { key: "Sat", value: CampaignWeek.Sat },
  ];
  private campaignProductBasis: any = [
    { key: "Primary Category", value: ProductBasisValuePair.PC },
    { key: "Secondary Category", value: ProductBasisValuePair.SC },
    { key: "Products", value: ProductBasisValuePair.Product },
  ];
    private campaignProductBasisOpen: any = [
    { key: "Primary Category", value: ProductBasisValuePair.PC },
    { key: "Secondary Category", value: ProductBasisValuePair.SC },
    { key: "Products", value: ProductBasisValuePair.Product },
    { key: "Shop" , value:ProductBasisValuePair.Outlet},
  ];
  private geoLevels: any = {};

  //private maxDaysFromStartDate: number = 45;
  //   'SKU': CampaignType.SKU,
  //   'Availability': CampaignType.Availability,
  //   'Planogram Compliance': CampaignType.Planogram_Compliance,
  //   'Shelf Share': CampaignType.Shelf_Share,
  //   'Other': CampaignType.Other,
  // };
  // private startDate:string="";
  private valid: boolean = false;
  // private readonly basicInfo: IBasicInfo = {
  //   Name: "",
  //   Description: "",
  //   StartDate: "",
  //   EndDate: "",
  //   CampaignType: CampaignType.SKU,
  //   BannerImageGuid: "",
  //   ContractLink: "",
  //   CampaignFrequency: CampaignFrequency.EveryVisit,
  //   CampaignWeekDays:[]
  // };
  private snackBarMessage: string = "";
  private snackbar: boolean = false;
  private snackColor: string = "red";
  checkbox = false;
  TaskMendatory = false;
  //  data(){
  //    return{
  //      checkbox: true,
  //    }
  //  }

  get GetTaskList() {
    var arr: Array<string> = [];
    
    if (this.Campaign.CampaignTaskDetail) {
      this.Campaign.CampaignTaskDetail.forEach((s: any) => {

         if(s.isVisible)
          {
            return arr.push(s)
          }
         
        });
    }

    return arr;
  }
  get GetTaskList1() {
          var arr: Array<string> = [];
          
          if (this.CampaignTaskDetails) {
            this.CampaignTaskDetails.forEach((s: any) => {
                if(s.isVisible)
                  return arr.push(s);
              
              });


            
          }

          return arr;
        }

  public IsOtherCampaignTypeSelected()
  {
    return this.Campaign.BasicInfo.CampaignType == CampaignType.Other;
  }

  public ValidateForm() {
    if (this.checkbox == false) {
      this.snackColor = "red";
      this.snackBarMessage = "Please confirm information before go live";
      this.snackbar = true;

      return;
    }
    return true;
  }
  submit() {
    var valid = this.ValidateForm();
    if (valid) {
      this.sumitCampaignInfo();
    }
  }
  CheckBox() {
    this.checkbox = !this.checkbox;
  }

  private sumitCampaignInfo() {
    if(this.$route.params.tocreateCampaign=='true')
    {
      CampaignService.submitCampaignInfo(this.Campaign)
      .then((res:any) => {
        this.snackColor = "green";
        this.snackBarMessage = "Campaign created successfully.!!";
        this.snackbar = true;
        setTimeout(() => this.$router.push('/Campaign/Manage'),2000);  
        // this.$router.go(0) 
        return;        
      })
      .catch((err:any) => {
        console.log(err);
        this.snackColor = "red";
        this.snackBarMessage = "Some error occured.";
        this.snackbar = true;
        return;
      });
    }
    console.log('Campaign', this.Campaign);
    if(this.$route.params.toeditCampaign=='true')
    {
      CampaignService.editCampaignInfo(this.Campaign,this.$route.params.ID)
      .then((res:any) => {
        this.snackColor = "green";
        this.snackBarMessage = "Campaign edited successfully.!!"
        this.snackbar = true;
        setTimeout(() => this.$router.push('/Campaign/Manage'),2000);  
        // this.$router.go(0) 
        return;        
      })
      .catch((err:any) => {
        console.log(err);
        this.snackColor = "red";
        this.snackBarMessage = "Some error occured.";
        this.snackbar = true;
        return;
      });
    }
  }

  // get maxEndDate(): string {
  //   var date = new Date(this.basicInfo.StartDate);
  //   date.setDate(date.getDate() + this.maxDaysFromStartDate);
  //   return date.toISOString().split("T")[0];
  // }

  closeSnackbar(value: boolean) {
    this.snackbar = value;
  }
  get startDt()
    {
        return moment(this.CampaignBasicInfo.startDate).format('YYYY-MM-DD');
    }

  async mounted() {
  const getResult = await CompanySettingService.companyUsesImageRecognition()
  this.getCompanySettingsService = getResult.data.result;
  }
}
// public closeEndDateMenu(){
//   // @ts-ignore
//   this.$refs['endDate'].closeMenu();
// }
