























































































































































































































































































































































































































































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import DatePicker from "@/components/common/Inputs/DatePicker.vue";;
import { CampaignType, CampaignFrequency } from "@/Enum/Campaign";
import { IBasicInfo, ICampaign } from "@/interfaces/Campaign";
import ListViewContainer from "@/components/common/containers/ListViewContainer.vue";
//import ShopContainer from "@/components/common/containers/ShopContainer.vue";
import { ICampaignConfig } from "@/interfaces/Campaign";
import { ProductBasisValuePair,CampaignBasis,IRCampaignType, IRSelectKPIs } from "@/Enum/Campaign";
import { CampaignWeek, CampaignApplicable } from "@/Enum/Campaign";
//import CampaignBasisSelection from "@/components/Campaign/New/Helpers/Product/CampaignBasisSelection.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import ImageUpload from "@/components/common/Upload/Image.vue";
import CampaignService from "@/services/CampaignService";
import Navbar from '@/components/common/Navbar.vue';
import moment from 'moment'
import ObjectView  from '@/components/Campaign/View/ObjectView.vue'
import CompanySettingService from "@/services/CompanySettingServics";
Vue.prototype.moment = moment

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";


@Component({
  components: {
    DatePicker,
    ListViewContainer,
    Snackbar,
    ValidationProvider,
    ValidationObserver,
    ImageUpload,
    Navbar,
    ObjectView
  },
})

export default class CampaignView extends Vue {
  @Prop() readonly Campaign!: ICampaign;
  @Prop() readonly step2DisplayData!: any;
  @Prop() readonly step3SurveyString!: string;
  @Prop() readonly viewCampaignInfo!:any;
  @Prop()  CampaignBasicInfo!:any;  
  @Prop() readonly CampaignTaskDetails!:any;
  @Prop() CampaignConfig!:any;
  

  public getCompanySettingsService:boolean = false;
  private IRDisplayTypeItems: any = []
  private campaignType: any = [
    { key: "Product Availability", value: CampaignType.SKU },
    { key: "Planogram Compliance", value: CampaignType.Planogram_Compliance },
    { key: "Shelf Share", value: CampaignType.Shelf_Share },
    { key: "Open Campaign", value: CampaignType.Other },
    { key:"Promo Audit", value: CampaignType.Promo},
    { key: "Competitor Survey", value: CampaignType.Competitor_Survey},
    { key: "IR Campaign", value: CampaignType.IR_Campaign},
  ];
  private selectKPIs: any = [
  { key: "Product Availability", value: IRSelectKPIs.ProductAvailability },
  { key: "SOS", value: IRSelectKPIs.SOS },
]
  private TaskFrequency: any = [
    { key: "Every Visit", value: CampaignFrequency.EveryVisit },
    { key: "Daily", value: CampaignFrequency.Daily },
    {
      key: "Once every month, anyday",
      value: CampaignFrequency.OnceEveryMonth,
    },
    {
      key: "Defined Day(s) of the week",
      value: CampaignFrequency.DefinedDayOfWeek,
    },
  ];
  private campaignProductBasis: any = [
      { key: "Primary Category", value: ProductBasisValuePair.PC },
      { key: "Secondary Category", value: ProductBasisValuePair.SC },
      { key: "Products", value: ProductBasisValuePair.Product },
    ];
    private campaignApplicable: any=[
    {key:"Promotor(ISR)", value:CampaignApplicable.ISR},
    {key:"Merchandiser", value:CampaignApplicable.Merchandiser},
    {key:"SR", value:CampaignApplicable.SR}
  ]
  private irCampaignTypeItems : any = [
    { key:"FA IR",value: IRCampaignType.FAIR },
    { key:"Partner IR",value: IRCampaignType.PartnerIR }
  ];
  private WeekDays = [
    { key: "Sun", value: CampaignWeek.Sun },
    { key: "Mon", value: CampaignWeek.Mon },
    { key: "Tue", value: CampaignWeek.Tue },
    { key: "Wed", value: CampaignWeek.Wed },
    { key: "Thu", value: CampaignWeek.Thur },
    { key: "Fri", value: CampaignWeek.Fri },
    { key: "Sat", value: CampaignWeek.Sat },
  ];
  private campaignProductBasisOpen: any = [
    { key: "Primary Category", value: CampaignBasis.PC },
    { key: "Secondary Category", value: CampaignBasis.SC },
    { key: "Products", value: CampaignBasis.Product },
    { key: "Shop", value: CampaignBasis.Outlet },
  ];
  private geoLevels: any = {};
  

  private valid: boolean = false;
  // private readonly basicInfo: IBasicInfo = {
  //   Name: "",
  //   Description: "",
  //   StartDate: "",
  //   EndDate: "",
  //   CampaignType: CampaignType.SKU,
  //   BannerImageGuid: "",
  //   ContractLink: "",
  //   CampaignFrequency: CampaignFrequency.EveryVisit,
  //   CampaignWeekDays:[]
  // };
  private snackBarMessage: string = "";
  private snackbar: boolean = false;
  private snackColor: string = "red";



  get GetTaskList() {
    var arr: Array<string> = [];
    
    if (this.CampaignTaskDetails) {
      this.CampaignTaskDetails.forEach((s: any) => {
          if(s.isVisible)
            return arr.push(s);
        
        });


      
    }

    return arr;
  }

  public IsOtherCampaignTypeSelected()
  {
    false
  }
  @Emit("toInitialPage")
  toInitialPage(){
  return;
  }
  get startDate()
  {
    return moment(this.CampaignBasicInfo.startDate).format('ddd, DD-MMM-YYYY');
  }
  get endDate(){
    return moment(this.CampaignBasicInfo.endDate).format('ddd, DD-MMM-YYYY');
  }

  async mounted() {
    const apiDataForDisplayType = await  CampaignService.GetDisplayTypesForIRCampaign()
    this.IRDisplayTypeItems = apiDataForDisplayType.data.map((item: { name: any; displayEnum: any; }) => {
    return { key: item.name, value: item.displayEnum };
    });  
    const getResult = await CompanySettingService.companyUsesImageRecognition()
    this.getCompanySettingsService = getResult.data.result;
  }

}


