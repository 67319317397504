











































































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import SideDrawer from "@/components/common/SideDrawer.vue";
import ProductService from "@/services/ProductService";
import ProductFilter from "@/components/Campaign/New/Helpers/Product/ProductFilter.vue";

@Component({
  components: { SideDrawer, ProductFilter },
})
export default class Step2PSD extends Vue {
  @Prop({ default: false, required: true })
  readonly sideDrawer!: boolean;

  @Emit("closeDrawer")
  closeDrawer(val: boolean) {
    this.resetValues();
    return val;
  }

  resetValues() {
    this.selectedProducts = [];
    this.pcSelection = [];
    this.scSelection = [];
    this.productValues = [];
  }

  private pcSelection: Array<number> = [];
  private pcValues: any = [];
  private scSelection: Array<number> = [];
  private scValues: any = [];
  private selectedProducts: Array<any> = [];
  private productValues: Array<any> = [];

  private GetProductValues() {
    let that = this;
    var body = { pcIds: this.pcSelection, scIds: this.scSelection };
    ProductService.getAllProduct(body)
      .then((res) => {
        that.productValues = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getSCValues() {
    let that = this;
    if (this.pcSelection.length == 0) {
      ProductService.getAllSC()
        .then((res) => {
          that.scValues = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      ProductService.getAllSCForPCIds(this.pcSelection)
        .then((res) => {
          that.scValues = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  mounted() {
    let that = this;
    ProductService.getAllPC()
      .then((res) => {
        that.pcValues = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    ProductService.getAllSC()
      .then((res) => {
        that.scValues = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private GetSelectedProducts(val:Array<any>)
  {
    this.selectedProducts = val;
  }

  private applySideDrawerProductChanges() {
    this.$emit("SelectedProducts", this.selectedProducts);
    this.resetValues();
  }
}
