

































































































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import StepperHeader from "@/components/Campaign/New/Header.vue";
import NavBar from "../../common/Navbar.vue";
import Step1 from "@/components/Campaign/New/Steps/Step1.vue";
import Step2 from "@/components/Campaign/New/Steps/Step2.vue";
import Step4 from "@/components/Campaign/New/Steps/Step4.vue";
import Step3 from "@/components/Campaign/New/Steps/step3.vue";
import Step3Other from "@/components/Campaign/New/Steps/Step3Other.vue";
import CampaignService from "@/services/CampaignService";
import GeographyService from "@/services/GeographyService";
import {
  CampaignType,
  CampaignFrequency,
  CampaignWeek,
  ProductBasisValuePair,
  CampaignBasis,
  IRCampaignType,
  IRSelectKPIs,
} from "@/Enum/Campaign";
import router from "@/router/routes";
import { ICampaign } from "@/interfaces/Campaign";
import { IBasicInfo, ICampaignListInfo } from "@/interfaces/Campaign";

@Component({
  components: { StepperHeader, NavBar, Step1, Step2, Step3, Step4, Step3Other },
})
export default class CreateCampaign extends Vue {
  @Prop() toManage!: boolean;
  private currentStep: Number = 1;
  private valid: Boolean = false;
  // @ts-ignore
  private campaign: ICampaign = this.InitializeCampaign();
  private productBasis: string = "";
  private IRProductAvailabilityBasis: string = "";
  private employeeBasis: string = "";
  private IRDisplayTypeItems: any = [];
  private geoSelectionValues: string = "";
  private step2DisplayData: any = {};
  private toCampaignManage: boolean = false;
  private step3SurveyString: string = "";
  public createCampaign: string = this.$route.params.tocreateCampaign;
  public editCampaign: string = this.$route.params.toeditCampaign;
  public campid: any = this.$route.params.ID;
  private campaignInfo: any = {};
  private campaignBasicInfo: any = {};
  private campaignTaskDetails: any = [];
  private campaignConfigDisplay: any = {};
  public outletvals: any = [];
  public geoTypeId: string = "";

  public InitializeCampaign() {
    var obj: ICampaign = {
      BasicInfo: {
        Name: "",
        Description: "",
        StartDate: "",
        EndDate: "",
        CampaignType: CampaignType.SKU,
        IRCampaignType: IRCampaignType.FAIR,
        IRCampaignIdentifier: "",
        IRKPIs:[],
        IRDisplayTypes: [],
        CampaignBasis: CampaignBasis.PC,
        IRProductAvailabilityBasis:CampaignBasis.PC,
        IRSOSBasis:CampaignBasis.PC,
        CampaignEmployeeType: [],
        BannerImageGuid: "",
        ContractLink: "",
        CampaignFrequency: CampaignFrequency.EveryVisit,
        CampaignWeekDays: [],
        RequiredForEndDay: false,
        IsAllProductBasisRequired: false,
        IsStockOutAlertsEnabled: false,
        IsSosTargetBreachAlertEnabled:false,
        IsMBQBreachAlertEnabled: false,
        IsCampaignSaveAsDraft: false,
        UsesImageRecognition: false,
      },
      CampaignConfig: {
        geoType: "",
        geoValue: [],
        chains: [],
        outlets: [],
        productBasis: null,
        productBasisValues: [],
        IRProductAvailabilityBasis:null,
        IRProductAvailabilityBasisValues:[],
        IRSosBasis:null,
        IRSosBasisValues:[],
      },
      CampaignTaskDetail: [],
      surveyId: 0,
    };
    return obj;
  }

  public CheckOtherCampaignType() {
    return this.campaign.BasicInfo.CampaignType == CampaignType.Other;
  }

  public navigateNext() {
    if (this.currentStep == 1) {
      // @ts-ignore
      this.valid = this.$refs.step1.ValidateForm();
      if (this.valid) {
        // @ts-ignore
        this.campaign.BasicInfo = this.$refs.step1.GetStepData();
      (this.$refs.step2 as any).UpdateBasisInfo();
        // @ts-ignore
        this.currentStep = this.currentStep + 1;
      }
      return;
    } else if (this.currentStep == 2) {
      // @ts-ignore
      this.valid = this.$refs.step2.ValidateForm();
      if (this.valid && this.createCampaign == "true") {
        // @ts-ignore
        this.campaign.CampaignConfig = this.$refs.step2.GetStepData();
        // @ts-ignore
        this.step2DisplayData = this.$refs.step2.GetDataForStep4();
        // @ts-ignore
        if(this.campaign.BasicInfo.CampaignType == 6)
        {
          this.currentStep = Number(this.currentStep) + 2;
        }else{
        this.currentStep = Number(this.currentStep) + 1;
        }
      }
      if (this.valid && this.editCampaign == "true") {
        // @ts-ignore
        this.campaign.CampaignConfig = this.$refs.step2.GetStepData();
        // @ts-ignore
        this.step2DisplayData = this.$refs.step2.GetDataForStep4();
        // @ts-ignore
        this.currentStep = this.currentStep + 2;
      }
      return;
    } else if (this.currentStep == 3) {
      // @ts-ignore
      this.valid = this.$refs.step3.ValidateForm();
      if (this.valid) {
        if (!this.CheckOtherCampaignType()) {
          // @ts-ignore
          this.campaign.CampaignTaskDetail = this.$refs.step3.GetStepData();
        } else {
          // @ts-ignore
          this.campaign.surveyId = this.$refs.step3.GetStepSurveyData();
          // @ts-ignore
          this.step3SurveyString = this.$refs.step3.GetDataForStep4();
        }
        // @ts-ignore
        this.currentStep = this.currentStep + 1;
        //this.sumitCampaignInfo();
      }
      return;
    }
  }
  navigate() {
    if (this.currentStep == 4 && this.editCampaign == "true") {
      // @ts-ignore
      this.valid = this.$refs.step1.ValidateForm();
      // @ts-ignore
      this.currentStep = this.currentStep - 2;

      return;
    } else if (this.currentStep == 4 && this.createCampaign == "true") {
      // @ts-ignore
      this.valid = this.$refs.step1.ValidateForm();
      if(this.campaign.BasicInfo.CampaignType == 6)
      this.currentStep = Number(this.currentStep) - 2
      else{
      // @ts-ignore
      this.currentStep = this.currentStep - 1;
      }

      return;
    } else if (this.currentStep == 3) {
      // @ts-ignore

      // @ts-ignore
      this.currentStep = this.currentStep - 1;

      return;
    } else if (this.currentStep == 2) {
      // @ts-ignore

      // @ts-ignore
      this.currentStep = this.currentStep - 1;
      //this.sumitCampaignInfo();

      return;
    } else if (this.currentStep == 1) {
      router.push("/campaign/manage");
    }
  }
  // @Emit("Manage")
  // Manage(){
  //   return this.toCampaignManage;
  // }
  public sumitCampaignInfo() {
    CampaignService.submitCampaignInfo(this.campaign)
      .then((res: any) => {})
      .catch((err: any) => {
        console.log(err);
      });
  }

  public getOutletVals() {
    let that = this;
    if (!this.campaignConfigDisplay.geoValuesId) {
      that.outletvals = {};
    }

    if (this.campaignConfigDisplay.geoTypeText == "Territory")
      this.geoTypeId = "20";
    if (this.campaignConfigDisplay.geoTypeText == "Region")
      this.geoTypeId = "30";
    if (this.campaignConfigDisplay.geoTypeText == "Level4"|| this.campaignConfigDisplay.geoTypeText == "Zone")
      this.geoTypeId = "40";
    if (this.campaignConfigDisplay.geoTypeText == "Level5")
      this.geoTypeId = "50";
    if (this.campaignConfigDisplay.geoTypeText == "Level6")
      this.geoTypeId = "60";
    if (this.campaignConfigDisplay.geoTypeText == "Level7")
      this.geoTypeId = "70";

    //console.log(geoTypeId);
    //console.log(geoLevelIds);
    GeographyService.GetOutletsForGeoType(
      this.geoTypeId,
      this.campaignConfigDisplay.geoValuesId
    )
      .then((res: any) => {
        that.outletvals = res.data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  async mounted() {
    const res = await CampaignService.getCampaignInfo(this.$route.params.ID);
    this.campaignInfo = res.data.campaignModel;
    this.campaignBasicInfo = res.data.campaignModel.basicInfo;
    this.campaignTaskDetails = res.data.campaignModel.campaignTaskDetail;
    this.campaignConfigDisplay = res.data.campaignConfigDisplay;
    this.getOutletVals();
  }
}
