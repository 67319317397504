










































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import {
  CampaignType,
  CampaignFrequency,
  CampaignWeek,
  ProductBasisValuePair,
  CampaignBasis,
} from "@/Enum/Campaign";
import SurveyService from "@/services/SurveyService";
import ConfugurationService from "@/services/ConfigurationServices";

@Component({
  components: {},
})
export default class Step3Other extends Vue {
  private allServeys: Array<any> = [];
  private serveySelection: number = 0;
    private portalBaseUrl: string = '';

  GetSurveys(){
    let that = this;
    SurveyService.getSurveys()
      .then((res: any) => {
        that.allServeys = res.data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  GetUrl()
  {
    let that=this;
    ConfugurationService.getPortalBaseUrl()
    .then((res:any)=>{
      that.portalBaseUrl = res.data;
    })
    .catch((err:any)=>{
      console.log(err);
    });
  }

  public ValidateForm() {
      return this.serveySelection!=0;
  }

  public GetData() {
      return this.serveySelection;
  }

  public GetSurveyName() {
      return this.allServeys.find(s=>s.id==this.serveySelection).name;
  }
  mounted(){
    this.GetSurveys()
    this.GetUrl()
  }
}
