

















































































    import {Component, Vue} from 'vue-property-decorator';
    import Navbar from '@/components/common/Navbar.vue';
    import {ILink, ITableHeader } from '@/interfaces/common';
    import DataTable from '@/components/common/Tables/DataTable.vue'
    import { IBasicInfo, ICampaignListInfo } from '@/interfaces/Campaign';
    import { CampaignFrequency } from '@/Enum/Campaign';
    import SideDrawer from '@/components/common/SideDrawer.vue';
    import DetailsCard from "@/components/common/DetailsCard.vue";
    import CreateCampaign from "@/components/Campaign/New/Create.vue";
    import CampaignService from "@/services/CampaignService";
    import ActionablePopup from '@/components/common/Popup/ActionablePopup.vue'
    import router from '@/router/routes';
    import Snackbar from '@/components/common/Snackbar.vue'
    import CampaignView from '@/components/Campaign/View/CampaignView.vue'
    import { ICampaign } from "@/interfaces/Campaign";
    import moment from 'moment'
    Vue.prototype.moment = moment
    @Component({
        components:{
            Navbar,
            DataTable,
            SideDrawer,
            DetailsCard,
            ActionablePopup,
            Snackbar,
            CreateCampaign,
            CampaignView
        }
    })

export default class ManageCampaignHeader extends Vue {
    
    private totalCampaign:number=0;
    private menu: Array<ILink>=[
        {
            Text: "Deactivate",
            Color: "red"
        },
        {
            Text: "Edit",
            Color: "primaryFABlue"
        }
    ];
    private inactiveCampaigns:boolean=false;
    private campaignList: Array<ICampaignListInfo>=[]   
    private List:Array<ICampaignListInfo>=[]  
    private createCampaign:boolean=false;
    private selectedId:any=0
    private Manage:boolean=false;
    private ToManage:boolean=false;
    private campaignInfo:any={}
    private campaignBasicInfo:any={}
    private campaignTaskDetails:any=[]
    private campaignConfigDisplay:any={}
    private toviewCampaign:boolean=false;
      private campaignType: Array<any> = [
    { key: 'SKU_Availability', value: 'Product Availability' },
    { key: "Planogram_Compliance", value: "Planogram Compliance" },
    { key: "Shelf_Share", value: "Shelf Share" },
    { key: "Other", value: "Open Campaign" },
    {key:"Promo", value:"Promo Audit"},
    {key:"Competitor_Survey", value:"Competitor Survey"},
    {key:"IR_Campaign", value:"IR Campaign"},
  ]
   private campaignProductBasis : Array<any> = [
    { key:"PC", value: 'Primary Category' },
    { key:"SC", value: 'Secondary Category'},
    { key:"Outlet", value:'Shop'},
    {key:"Product", value:'Product'},
    {key: "Multiple", value: 'Multiple'}
  ];
    private TaskFrequency: Array<any>=[
    { key: "EveryVisit", value: 'Every Visit'},
    { key: "OnceEveryWeek", value: 'Daily'},
    { key: "DefinedDayOfWeek", value: 'Defined Day(s) of the week'},

  ]
    
    toEdit(campaign:ICampaignListInfo){
        this.$router.push({ path:'/campaign/create',name:'campaignCreate',params: {tocreateCampaign:'false',toeditCampaign:'true',ID:campaign.id}})
    }
    
    private tableHeaders: Array<ITableHeader>=[
        {
            text:'Campaign Name',
            value:'name',
            sortable: true,
            default: ''
        },
        {
            text:'Type',
            value:'type',
            sortable: false,
            default: ''
        },
        {
            text:'Basis',
            value:'basis',
            sortable: false,
            default: ''
        },
        {
            text:'Frequency',
            value:'frequency',
            sortable: false,
            default: ''
        },
        {
            text:'Start Date',
            value:'startDate',
            sortable: false,
            default: ''
        },
        {
            text:'End Date',
            value:'endDate',
            sortable: false,
            default: ''
        },
        {
            text:'Actions',
            value:'action',
            sortable: false,
            default: ''
        }
    ]
    private confirmPopupTitle:string=""
    private confirmPopupSubtitle:string=""
    private confirmPopupText:string=""
    private cancelBtnText:string=""
    private confirmBtnText:string=""
    private confirmationPopup:boolean=false
    private snackbar:boolean=false
    private snackbarcolor: string='success'
    private message:string=''

    public menuActions(campaign:ICampaignListInfo, Index:number){
        this.selectedId=campaign.id;
        switch(this.menu[Index].Text){
            case "Deactivate":
                this.confirmPopupTitle="Confirm Deactivation";
                this.confirmPopupSubtitle="Field Reps will not get this task anymore.";
                this.confirmPopupText="You are deactivating '"+campaign.name+"' campaign.";
                this.cancelBtnText="Cancel";
                this.confirmBtnText="Deactivate";
                this.confirmationPopup=true;
                break;
            case "Edit":
                this.toEdit(campaign);
                break;
        }
    }
    navigateToCreate(){
        //router.push('/Campaign/Create')
        this.$router.push({ path:'/campaign/create',name:'campaignCreate',params: {tocreateCampaign:'true',toeditCampaign:'false'}})
    }

    toView(campaign:ICampaignListInfo){
        this.toviewCampaign=true;
        let that=this;
        CampaignService.getCampaignInfo(campaign.id)
        .then((res:any)=>{ 
            that.campaignInfo=res.data.campaignModel;
           that.campaignBasicInfo=res.data.campaignModel.basicInfo;
           that.campaignTaskDetails=res.data.campaignModel.campaignTaskDetail;
           that.campaignConfigDisplay=res.data.campaignConfigDisplay;
        })
        .catch((err:any)=>{
            console.log(err);
        });
    }
    public confirmAction(value:any):void{
        CampaignService.deactivateCampaign(value).then((res) =>{
            this.snackbarcolor="red";
            this.message="Campaign Deactivated.";
            this.actionablePopup();
            this.refreshCampaignList();
            
        }).catch((err)=> {
            console.log(err);
        })

    }

    public async refreshCampaignList(){
        this.inactiveCampaigns? await this.getInactiveCampaigns(): await this.getActiveCampaigns();
    }

    public actionablePopup(){
        this.snackbar = true;
        this.confirmationPopup = false;
        }

    public closeSnackbar(value: boolean): void{
        this.snackbar = value;
    }

    public switchCampaign(){
        if(this.inactiveCampaigns){
            this.getInactiveCampaigns();
        }
        else{
            this.getActiveCampaigns();
        }
       
    }
    Back()
    {
        this.toviewCampaign=false;
    }

    public async getActiveCampaigns():Promise<boolean>{
        let that=this;
        that.totalCampaign=0;
        return new Promise<boolean>(res =>{
        CampaignService.getList()
        .then((response:any) => {
          that.campaignList=[];
          response.data.forEach((element:any, index: number)=>{
              var item: ICampaignListInfo={
                  id:"",
                  name:"",
                  basis:"",
                  type:"",
                  weekDays:"",
                  frequency:"",
                  startDate:"",
                  endDate:"",
                  isDisabled:false,
              };
              item.id=element.id;
              item.name=element.name;
              item.type=element.type;
              item.basis=element.basis;
              item.weekDays=element.weekDays;
              item.frequency=element.frequency;
              item.startDate=element.startDate;
              item.endDate=element.endDate;
              item.isDisabled=element.isDisabled;
              if(!item.isDisabled){
              that.campaignList.push(item);
              that.totalCampaign=that.totalCampaign+1;
              }
              res(true);
          });
      })
      .catch((err:any) => {
        console.log(err);
      });
    })
    }
    public async getInactiveCampaigns():Promise<boolean>{
        let that=this;
        that.totalCampaign=0;
        return new Promise<boolean>(res =>{
        CampaignService.getList()
        .then((response:any) => {
          that.campaignList=[];
          response.data.forEach((element:any, index: number)=>{
              var item: ICampaignListInfo={
                  id:"",
                  name:"",
                  basis:"",
                  type:"",
                  weekDays:"",
                  frequency:"",
                  startDate:"",
                  endDate:"",
                  isDisabled:false,
              };
              item.id=element.id;
              item.name=element.name;
              item.type=element.type;
              item.basis=element.basis;
              item.frequency=element.frequency;
              item.weekDays=element.weekDays;
              item.startDate=element.startDate;
              item.endDate=element.endDate;
              item.isDisabled=element.isDisabled;
              if(item.isDisabled){
              that.campaignList.push(item);
              that.totalCampaign=that.totalCampaign+1;
              }
              res(true);
          });
      })
      .catch((err:any) => {
        console.log(err);
      });
    })
    }



    mounted(){
        this.getActiveCampaigns()

    }

}
