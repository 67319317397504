


























import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";

@Component({})
export default class StepperHeader extends Vue {
  @Prop() readonly StepNumber!: number;
}
