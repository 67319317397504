import client from './../apiService';

class SurveyService
{

    getSurveys(){
        const url=`Survey/GetInStoreExecutionSurveys`;
        return client.get(url,{
            headers:{
                'Content-Type':'application/json'
            }
        })
    }

    getPortalUrl(){
        const url=`Survey/GetPortalBasePath`;
        return client.get(url,{
            headers:{
                'Content-Type':'application/json'
            }
        })
    }
}


export default new SurveyService();