
























































































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import ProductService from "@/services/ProductService";
import ChainService from "@/services/ChainService";
import ProductSideDrawer from "@/components/Campaign/New/Helpers/Product/ProductSideDrawer.vue";
import { ProductBasisValuePair,CampaignBasis } from "@/Enum/Campaign";
import CompanySettingService from "@/services/CompanySettingServics";
import CampaignService from "@/services/CampaignService";

@Component({
  components: { ProductSideDrawer },
})
export default class CampaignBasisSelection extends Vue {
  @Prop() readonly createCampaign!: string;
  @Prop() readonly editCampaign!: string;
  @Prop() readonly CampaignConfig!:any;
  @Prop() readonly ProductBasis!: number;
  @Prop() readonly ProductBasis_edit!: number;
  @Prop() readonly ProductItems!: Array<any>;
  @Prop() readonly ProductItems_edit!: Array<any>;
  @Prop() readonly IsSosOrPA: string = "";
  
  private items: Array<any> = [];
  private items1: Array<any> = [];
  public getCompanySettingsService:boolean = false;
  private selected: Array<any> = [];
  private selected_edit: Array<any> = [];
  private campaignConfigDisplay:any={};
  private sideDrawer: boolean = false;
    
  public campaignProductBasis : any = {
    [CampaignBasis.PC] : "Primary Category",
    [CampaignBasis.SC] : "Secondary Category",
    [CampaignBasis.Product] : "Products"
  };

  @Watch("selected")
  SyncSelection() {
    this.$emit("SyncProducts", this.selected);
  }
  
  @Watch("selected_edit")
  SyncSelection1() {
    this.$emit("SyncProducts", this.selected_edit);
  }

  @Watch("ProductBasis_edit")
  async fun1() {
    const res = await CampaignService.getCampaignInfo(this.$route.params.ID)
  this.campaignConfigDisplay=res.data.campaignConfigDisplay;
  if(res.data.campaignModel.basicInfo.campaignType == 6)
  {
    if(this.IsSosOrPA == "SOS")
    {
      this.selected_edit= (this.campaignConfigDisplay.irSosBasisIds);
    }
    if(this.IsSosOrPA == "PA")
    {
      this.selected_edit= (this.campaignConfigDisplay.irProductAvailabilityBasisIds);
    }
  }
  else{
  this.selected_edit= (this.campaignConfigDisplay.productBasisIds);
  }
  }

  RemoveChip(index: number) {
    this.selected.splice(index, 1);
  }
   
  RemoveChip1(index: number) {
    this.selected_edit.splice(index, 1);
  }

  private toggleSideDrawer(value: boolean) {
    this.sideDrawer = value;
  }

  icon () {
      if (this.selected.length === this.items.length){
        return 'mdi-close-box';
      } else if ((this.selected.length > 0) && !(this.selected.length === this.items.length)){
        return 'mdi-minus-box';
      }else {
        return 'mdi-checkbox-blank-outline';
      }
  }

  icon1 () {
      if (this.selected_edit.length === this.items1.length){
        return 'mdi-close-box';
      } else if ((this.selected_edit.length > 0) && !(this.selected_edit.length === this.items1.length)){
        return 'mdi-minus-box';
      }else {
        return 'mdi-checkbox-blank-outline';
      }
  }

  toggle () {
      this.$nextTick(() => {
        if (this.selected.length === this.items.length) {
          this.selected = [];
        } else {
          this.selected = [];
         this.items.forEach((item) => {
          this.selected.push(item.id); 
         })
        }
      })
    }

    toggle1 () {
      this.$nextTick(() => {
        if (this.selected_edit.length === this.items1.length) {
          this.selected_edit = [];
        } else {
          this.selected_edit = [];
         this.items1.forEach((item) => {
          this.selected_edit.push(item.id); 
         })
        }
      })
    }

  private SelectedProductsFromSideDrawer(valArray: Array<any>) {
    this.sideDrawer = false;
    if(this.createCampaign == "true")
    {
      valArray.forEach((e) => {
      // @ts-ignore
      if (!this.selected.find((f) => f == e.id)) {
        this.selected.push(e.id);
      }
    });
    }
    else if(this.editCampaign == "true")
    {
      valArray.forEach((e) => {
      // @ts-ignore
      if (!this.selected_edit.find((f) => f == e.id)) {
        this.selected_edit.push(e.id);
      }
    });
    }
   
  }

  public GetDataForStep4(){
    var arr = [];
    if(this.createCampaign=='true'){
      arr = this.selected.map(s=> this.items.find(t=>t.id==s).name);
    }
    if(this.editCampaign=='true'){
      arr = this.selected_edit.map(s=> this.items1.find(t=>t.id==s).name);
    }
    return arr;
  }

  @Watch("ProductItems")
  addItems() {
    this.items=this.ProductItems
  }

  @Watch("ProductItems_edit")
  addItems1() {
    this.items1=this.ProductItems_edit
  }

  @Watch("ProductBasis")
  async UpdatedProductBasis(ChainIds: Array<number>) {
    let that = this;
    const getResult =   await CompanySettingService.companyUsesChainSKUMapping()
    if (this.campaignProductBasis[this.ProductBasis] == "Primary Category") {
      ProductService.getAllPC()
        .then((res) => {
          that.items = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (this.campaignProductBasis[this.ProductBasis] == "Secondary Category") {
      ProductService.getAllSC()
        .then((res) => {
          that.items = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    } else if ((this.campaignProductBasis[this.ProductBasis] == "Products" && getResult.data.result == true)) {
      
    }else if (this.campaignProductBasis[this.ProductBasis] == "Products"){
      ProductService.getAllProduct({})
        .then((res) => {
          that.items = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }else {
      ProductService.getAllProduct({})
        .then((res) => {
          that.items = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  @Watch("ProductBasis_edit")
  async UpdatedProductBasis1(ChainIds: Array<number>) {
    let that = this;
    const getResult =   await CompanySettingService.companyUsesChainSKUMapping()
    if (this.campaignProductBasis[this.ProductBasis_edit] == "Primary Category") {
      ProductService.getAllPC()
        .then((res) => {
          that.items1 = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (this.campaignProductBasis[this.ProductBasis_edit] == "Secondary Category") {
      ProductService.getAllSC()
        .then((res) => {
          that.items1 = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    } else if ((this.campaignProductBasis[this.ProductBasis_edit] == "Products" && getResult.data.result == true)) {
      
    }else if (this.campaignProductBasis[this.ProductBasis_edit] == "Products"){
      ProductService.getAllProduct({})
        .then((res) => {
          that.items1 = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }else {
      ProductService.getAllProduct({})
        .then((res) => {
          that.items1 = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async mounted() {
  const res = await CampaignService.getCampaignInfo(this.$route.params.ID)
  this.campaignConfigDisplay=res.data.campaignConfigDisplay;
  if(res.data.campaignModel.basicInfo.campaignType == 6)
  {
    if(res.data.campaignModel.basicInfo.irsosBasis != null && res.data.campaignModel.basicInfo.irProductAvailabilityBasis == null)
    {
       this.selected_edit= (this.campaignConfigDisplay.irSosBasisIds);
    }
    else if(res.data.campaignModel.basicInfo.irsosBasis == null && res.data.campaignModel.basicInfo.irProductAvailabilityBasis != null)
    {
      this.selected_edit= (this.campaignConfigDisplay.irProductAvailabilityBasisIds);
    }
    else
    {
       if(res.data.campaignModel.basicInfo.irsosBasis == this.ProductBasis_edit)
       {
        this.selected_edit= (this.campaignConfigDisplay.irSosBasisIds);
       }
       if(res.data.campaignModel.basicInfo.irProductAvailabilityBasis == this.ProductBasis_edit)
       {
        this.selected_edit= (this.campaignConfigDisplay.irProductAvailabilityBasisIds);;
       }
    }
  }
  else{
  this.selected_edit= (this.campaignConfigDisplay.productBasisIds);
  }
    
  const getResult = await CompanySettingService.companyUsesChainSKUMapping()
  this.getCompanySettingsService = getResult.data.result;
  }
}
