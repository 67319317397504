



































































































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import Step3Other from "@/components/Campaign/New/Steps/Step3Other.vue";
import CampaignService from "@/services/CampaignService";
import {
  IBasicInfo,
  ICampaign,
  ICampaignTaskDetail,
} from "@/interfaces/Campaign";
import Snackbar from "@/components/common/Snackbar.vue";
import {
  CampaignType,
  CampaignFrequency,
  CampaignWeek,
  ProductBasisValuePair,
  CampaignBasis,
} from "@/Enum/Campaign";
@Component({
  components: { Snackbar, Step3Other },
})
export default class Step3 extends Vue {
  // private Qno = 1;
  // private visible = false;
  // private mandatory = false;
  // private questionFA: string = "Is the SKU/Category avaialble at store?";
  // private questionFR: string = "";

  // private valid: boolean = false;
  @Prop() readonly CampaignType!: number;
  @Prop() readonly createCampaign!: string;
  @Prop() readonly editCampaign!: string;
  @Prop() readonly campid!: any;
  @Prop() readonly Campaign!: ICampaign;
  @Prop() readonly CampaignBasicInfo!:any;
  @Prop() readonly CampaignConfig!:any;
  @Prop() readonly CampaignTaskDetails!:any;
  
  private questions: Array<ICampaignTaskDetail> = [];
  private visible: Array<boolean> = [];
  private Required:Array<boolean>=[];
  private Qno: number = 0;

  public Iterator(QNo: number) {
    this.Qno = QNo + 1;
  }

  public ValidateForm() {
    if (this.CheckOtherCampaignType()) {
      // @ts-ignore
      let valid = this.$refs.Step3Other.ValidateForm();
      if (!valid) {
        this.snackBarMessage ="Selection of survey is mandatory for other survey type.";
        this.snackbar = true;
        return;
      }
      return true;
    }
    // @ts-ignore
    if (!this.visible || this.visible.length == 0) {
      this.snackBarMessage = "Atleast 1 question should be visible.";
      this.snackbar = true;
      return;
    }
    if(this.visible.length<this.Required.length)
    {
      this.snackBarMessage="Mandatory questions needs to be visible."
      this.snackbar=true;
      return;
    }
    return true;
  }
  public validVisibleEntry(item: ICampaignTaskDetail) {
    if(item.isVisible==false)
    {
      item.isRequired=false;
      if(item.id == 'noOfRackCategory' || item.id == 'noOfRackCompany')
      {
        this.visible.pop();
        this.questions.forEach((ques) => {
        if(ques.id == 'showPercentageShare')
        { 
          if(ques.isVisible == true)
          {
            ques.isVisible = false
            ques.isRequired=false;
            this.visible.pop();
          }
        }
      })
      }
      else
      {
        this.visible.pop();
      }
    }
    else if(item.isVisible && item.id == 'showPercentageShare')
    {
      this.visible.push(item.isVisible);
      this.questions.forEach((ques) => {
        if(ques.id == 'noOfRackCategory' || ques.id == 'noOfRackCompany')
        {
          if(ques.isVisible == false){
          ques.isVisible = true
          this.visible.push(ques.isVisible);
        }
        }
      })
    }
      else if(item.isVisible==true)
    {
      this.visible.push(item.isVisible);
    }
  }
  public validRequired(item:ICampaignTaskDetail){
    if(item.isRequired==true){
      if(item.isVisible==false){
      item.isVisible=true;
      this.visible.push(item.isVisible);
      }
    }
 
  }

  public CheckOtherCampaignType() {
    return this.CampaignType == CampaignType.Other;
  }
  public d:any;
  @Watch("CampaignType")
  GetQuestionForCampaignType() {
    let that = this;
    CampaignService.getQuestions(this.CampaignType)
      .then((res: any) => {
        that.questions = [];
        that.d=res.data;
        res.data.forEach((element: any, index: number) => {
          var item: ICampaignTaskDetail = {
            id: "",
            questionNo: 0,
            questionInWords: "",
            isVisible: false,
            isRequired: false,
            questionFA: "",
            type: "",
            equation : "",
            visibility:"",
            IsFocused:false,
            Choices:[],
            numericId: 0
          };
          item.id = element.id;
          item.questionNo = index + 1;
          item.questionFA = element.questionFA;
          item.type = element.type;
          item.visibility= element.visibility;
          item.equation = element.equation;
          item.isVisible=element.isVisible;
          item.isRequired=element.isRequired;
          item.IsFocused=element.isFocused;
          item.Choices=element.choices;
          item.numericId = element.numericId;
          if(item.isVisible==true)
          {
            that.validVisibleEntry(item);
          }
          

          that.questions.push(item);
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  public GetStepData() {
    this.questions.forEach((s:any)=>{
      if(s.questionInWords==''&&s.isVisible)
      {
        s.questionInWords=s.questionFA;
      }
      else if(s.condition!=null)
      {
        s.questionInWords=s.questionFA;
      }
    })
    return this.questions;
  }

  public GetStepSurveyData(){
    // @ts-ignore
    return this.$refs.Step3Other.GetData();
  }

  public GetDataForStep4(){
    // @ts-ignore
    return this.$refs.Step3Other.GetSurveyName();
  }

  closeSnackbar(value: boolean) {
    this.snackbar = value;
  }

  private snackBarMessage: string = "";
  private snackbar: boolean = false;

  mounted() {
    let that = this;
    this.GetQuestionForCampaignType();
  }
}
