import client from './../apiService';
import { IChain } from "@/interfaces/MTChainModel";

class ChainService{
    getChainList(active:boolean){
        const url=  `Chain/GetChainList?active=`+active;
        return client.get(url,{
            headers: {
                'Content-Type': 'application/json'
            }          
        });
    }

    GetSkusForChain(ChainIds:Array<number>){
        const url= `Chain/GetProductsForChain`;
        return client.post(url,ChainIds,{
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    
    getChain_List(){
        const url=  `Chain/GetActiveChainList`;
        return client.get(url,{
            headers: {
                'Content-Type': 'application/json'
            }          
        });
    }
    CreateChain(name:string){
        const url= `Chain/Create?name=${name}`;
        return client.post(url,{
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    UpdateChain(chainId:number,name:string){
        const url=`Chain/UpdateChain?chainId=${chainId}&&name=${name}`;
        return client.post(url,{
            headers:{
                'Content-Type':'application/json'
            }
        });
    }
    DeactivateChain(chainId:number){
        const url=`Chain/DeactivateChain?chainId=${chainId}`;
        return client.post(url,{
            headers:{
                'Content-Type':'application/json'
            }            
        });
    }
    ActivateChain(chainId:number){
        const url=`Chain/ActivateChain?chainId=${chainId}`;
        return client.post(url,{
            headers:{
                'Content-Type':'application/json'
            }            
        });
    }
}

export default new ChainService();