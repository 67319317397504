




































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import ListSearchAndSelect from "@/components/common/List/ListSearchAndSelect.vue";

@Component({
  components: {
    ListSearchAndSelect,
  },
})
export default class ProductFilter extends Vue {
  @Prop() readonly productList!: Array<any>;

  private listingList: Array<any> = this.productList;
  private searchedList: Array<any> = this.listingList;
  private searchText: string='';

  private selectedList: Array<any> = [];
  
  @Watch('productList')
  changeInitValues()
  {
      this.listingList = this.productList;
      this.searchedList = this.listingList;
      this.selectedList = [];
      this.searchText = '';
  }

  @Watch('selectedList')
  emitSelectedProducts(){
      this.$emit('SelectedProducts', this.selectedList);
  }

  private selectProduct(itemSelected: any) {
    // Add in Seleted List
    this.selectedList.push(itemSelected);
    // Remove from Listing
    this.listingList = this.listingList.filter(function (ele) {
      return ele.id != itemSelected.id;
    });
    this.SearchTextChanged(this.searchText);
  }

  private SearchTextChanged(newVal: string){
      if(newVal==null || newVal==undefined)
      {
          newVal='';
      }
      this.searchText = newVal;
      this.searchedList = this.listingList.filter(item => {
          if(item.name.toUpperCase().includes(newVal.toUpperCase()))
          {
              return item;
          }
      });
  }

  private removeSelectedProduct(itemSelected:any)
  {
      // Add in Listing
    this.listingList.push(itemSelected);
    // Remove from selection List
    this.selectedList = this.selectedList.filter(function (ele) {
      return ele.id != itemSelected.id;
    });
    this.SearchTextChanged(this.searchText);
  }

  private selectAll(){
      this.listingList.forEach(item=> this.selectedList.push(item));
      this.listingList = [];
      this.SearchTextChanged(this.searchText);
  }
}
