








































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import SideDrawer from "@/components/common/SideDrawer.vue";
import GeographyService from "@/services/GeographyService";
import OutletFilter from "@/components/Campaign/New/Helpers/Outlet/OutletFilter.vue";

@Component({
  components: { SideDrawer, OutletFilter },
})
export default class Step2SD extends Vue {
  @Prop()
  readonly geoVales!: Array<any>;
  @Prop() readonly geoLabel!: string;
  @Prop() readonly geoEnum!: string;
  @Prop({ default: false, required: true })
  readonly sideDrawer!: boolean;

  @Emit("closeDrawer")
  closeDrawer(val: boolean) {
    this.resetValues();
    return val;
  }

  resetValues(){
    this.geoSelection= [];
    this.outletValues = [];
    this.selectedOutlets = [];
  }

  private geoSelection: Array<number> = [];
  private outletValues: any = [];
  private selectedOutlets: Array<any> = [];

  private getOutletValues(geoLevelIds: Array<number>) {
    let that = this;
    if (!geoLevelIds) {
      that.outletValues = {};
    }

    GeographyService.GetOutletsForGeoType(that.geoEnum, geoLevelIds)
      .then((res) => {
        that.outletValues = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private GetSelectedOutlets(val:Array<any>)
  {
    this.selectedOutlets = val;
  }

  private applySideDrawerOultetChanges() {
    this.$emit('SelectedOutlets', this.selectedOutlets);
    this.resetValues();
  }
}
