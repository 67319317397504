import client from './../apiService';
import { ICampaign } from "@/interfaces/Campaign";

class CampaignService{
    getQuestions(type:number){
        const url = `Campaign/GetQuestions?campaignType=`+type;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    submitCampaignInfo(model:ICampaign){
        const url = `Campaign/Create`;
        return client.post(url,model, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    editCampaignInfo(model:ICampaign,campaignId:any){
        const url=`Campaign/Edit?campaignId=${campaignId}`;
        return client.post(url,model,{
            headers:{
                'Content-Type':'application/json'
            }
        })
    }

    getList(){
        const url = `Campaign/GetCampaigns`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    deactivateCampaign(companyId:any){
        const url=`Campaign/DeactivateCampaign?companyId=${companyId}`;
        return client.get(url,{
            headers:{
                'Content-Type':'application/json'
            }
        })
    }
    getCampaignInfo(companyId:any){
        const url=`Campaign/GetCampaignForDisplay?campaignId=${companyId}`;
        return client.get(url,{
            headers:{
                'Content-Type':'application/json'
            }
        })
    }
    GetDisplayTypesForIRCampaign(){
        const url=`Campaign/GetDisplayTypesForIRCampaign`;
        return client.get(url,{
            headers:{
                'Content-Type':'application/json'
            }
        })
    }
}

export default new CampaignService();