





























import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ListViewContainer extends Vue {
  @Prop({ default: () => [] }) readonly list!: Array<string>;
  @Prop() readonly title!: string;
  private limit: Number=4;
  private default_limit:Number=4;
  private show: boolean=false; 
  private d: Array<String>=[];  
  private dummy: Array<String>=this.d ;   
  private displayList: Array<string> = this.list;    
  private i:number=0
 
  simple_toggle(default_limit:Number, filters_length:Number) {
        this.limit = (this.limit=== this.default_limit) ? filters_length : default_limit;
        this.i++
        if(this.i % 2==0)
        {
          this.dummy=this.d;
        }
        else{
          this.dummy=this.list
        }


  
        
    }

mounted(){
  this.d=(this.list.length<4 ? this.list : this.list.slice(0,4));
}
}
