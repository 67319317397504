export enum CampaignType {
    SKU,
    //Availability,
    Planogram_Compliance,
    Shelf_Share,
    Other,
    Promo,
    Competitor_Survey,
    IR_Campaign,
}

export enum CampaignFrequency {
    EveryVisit = 0,
    Daily = 1,
    OnceEveryMonth = 2,
    DefinedDayOfWeek = 3
}

export enum CampaignWeek {
    Sun = 0,
    Mon = 1,
    Tue = 2,
    Wed = 3,
    Thur = 4,
    Fri = 5,
    Sat = 6
}

export enum ProductBasisValuePair  {
    PC = 0,
    SC = 1,
    Product = 2,
    Outlet =3
}
export enum CampaignBasis  {
    PC = 0,
    SC = 1,
    Product = 2,
    Outlet = 3
}

export enum GeographyLevel{
    Territory = 20,
    Region = 30,
        Level4 = 40,
        Level5 = 50,
        Level6 = 60,
        Level7 = 70
}

export enum CampaignApplicable{
    ISR = 1,
    Merchandiser= 2,
    SR= 0
    
}

export enum IRCampaignType{
    FAIR = 0,
    PartnerIR= 1,
}

export enum IRSelectKPIs{
    ProductAvailability = 0,
    SOS = 1
}