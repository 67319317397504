




























































































































































































































































































































































































































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import GeographyService from "@/services/GeographyService";
import ChainService from "@/services/ChainService";
import Snackbar from '@/components/common/Snackbar.vue'
import SideDrawer from "@/components/Campaign/New/Helpers/Outlet/SideDrawer.vue";
import ActionablePopup from "@/components/common/Popup/ActionablePopup.vue";
import { ICampaignConfig } from "@/interfaces/Campaign";
import { IBasicInfo, ICampaign } from "@/interfaces/Campaign";
import { IRSelectKPIs,ProductBasisValuePair,CampaignBasis, CampaignType } from "@/Enum/Campaign";
import CampaignBasisSelection from "@/components/Campaign/New/Helpers/Product/CampaignBasisSelection.vue";
import{EntityMin} from "@/interfaces/EntityMin";
import CompanySettingService from "@/services/CompanySettingServics";
import ProductService from "@/services/ProductService";
import CampaignService from "@/services/CampaignService";

@Component({
  components: { SideDrawer, ActionablePopup, CampaignBasisSelection,Snackbar },
})
export default class Step2 extends Vue {
  @Prop() readonly createCampaign!: string;
  @Prop() readonly editCampaign!: string;
  @Prop() readonly campid!: any;
  @Prop() readonly ProductBasis!: any;
  @Prop() readonly Campaign!: ICampaign;
  @Prop() readonly CampaignBasicInfo!:any;
  @Prop() readonly CampaignConfig!:any;
  @Prop() readonly CampaignTaskDetails!:any;
  @Prop() readonly outletfn!:any;
  @Prop() readonly outletvals!:any;
  @Prop() readonly geoTypeId!:string;

  public items: Array<any> = [];
  public irProductAvailabilityBasisItems: Array<any> = [];
  public sosBasisItems: Array<any> = [];
  public items1: Array<any> = [];
  public irProductAvailabilityBasisItems1: Array<any> = [];
  public sosBasisItems1: Array<any> = [];
  private Shops: Array<string> = [
    "Type 1",
    "Type 2",
    "Type3",
    "Type4",
    "Type5",
  ];
  private campaignProductBasis : any = {
    [CampaignBasis.PC] : "Primary Category",
    [CampaignBasis.SC] : "Secondary Category",
    [CampaignBasis.Product] : "Products",
     [CampaignBasis.Outlet] : "Outlet"
  };
  private valid: boolean = false;
  private geoLevels: any = {};
  private selection: any = "";
  private geoSelectionType: any = null;
  private geoSelectionValues: any = [];
  private geoSelection: Array<any> = [];
  private geoSelectionName: Array<any>=[];
  protected outletValues: any = [];
  protected chainValues: any = [];
  private outletSelection: Array<any> = [];
  private chainSelection: Array<any> = [];
  private sideDrawer: boolean = false;
  private productHierarchySelection: Array<any> = [];
  private IRProductAvailabilityProductHierarchySelection: Array<any> = [];
  private IRSOSProductHierarchySelection: Array<any> = [];
  private dummy:Array<any>=[];
  private xyz:Array<any>=[88,86];
  private x:string="";  
  private campaignConfigDisplay:any={};
  private campaignBasicInfo:any={}
  
  public syncProducts(val: any) {
    this.productHierarchySelection = val;
  }

  public syncIRProductAvailability(val: any) {
    this.IRProductAvailabilityProductHierarchySelection = val;
  }

  public syncIRSOS(val: any) {
    this.IRSOSProductHierarchySelection = val;
  }

  public CheckCampaignBasisSelectionShow()
  {
    return this.ProductBasis != CampaignBasis.Outlet;
  }
  
  public async UpdateBasisInfo()
  {
    var that = this
    if(this.Campaign.BasicInfo.CampaignType == 6)
    {
      if(this.Campaign.BasicInfo.IRKPIs.includes(IRSelectKPIs.ProductAvailability))
      {
       if (this.campaignProductBasis[this.Campaign.BasicInfo.IRProductAvailabilityBasis] == "Primary Category") {
          ProductService.getAllPC()
            .then((res) => {
              that.irProductAvailabilityBasisItems = res.data;
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (this.campaignProductBasis[this.Campaign.BasicInfo.IRProductAvailabilityBasis] == "Secondary Category") {
          ProductService.getAllSC()
            .then((res) => {
              that.irProductAvailabilityBasisItems = res.data;
            })
            .catch((err) => {
              console.log(err);
            });
        }else if (this.campaignProductBasis[this.Campaign.BasicInfo.IRProductAvailabilityBasis] == "Products"){
          ProductService.getAllProduct({})
            .then((res) => {
              that.irProductAvailabilityBasisItems = res.data;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      if(this.Campaign.BasicInfo.IRKPIs.includes(IRSelectKPIs.SOS)){
        if (this.campaignProductBasis[this.Campaign.BasicInfo.IRSOSBasis] == "Primary Category") {
          ProductService.getAllPC()
            .then((res) => {
              that.sosBasisItems = res.data;
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (this.campaignProductBasis[this.Campaign.BasicInfo.IRSOSBasis] == "Secondary Category") {
          ProductService.getAllSC()
            .then((res) => {
              that.sosBasisItems = res.data;
            })
            .catch((err) => {
              console.log(err);
            });
        }else if (this.campaignProductBasis[this.Campaign.BasicInfo.IRSOSBasis] == "Products"){
          ProductService.getAllProduct({})
            .then((res) => {
              that.sosBasisItems = res.data;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  }

  public ValidateForm() {
    // @ts-ignore
    if ((!this.campaignConfig.geoValue || this.campaignConfig.geoValue.length == 0) && this.createCampaign == 'true') {
        this.snackBarMessage = 'Choosing sales territory is mandatory.';
        this.snackbar = true;
        return;
    }
    // @ts-ignore
    else if((!this.productHierarchySelection || this.productHierarchySelection.length == 0)
      && (this.createCampaign !== 'false' && this.ProductBasis!=CampaignBasis.Outlet))
    {
      if((this.createCampaign !== 'false' && this.Campaign.BasicInfo.CampaignType == 6) ||  (this.campaignBasicInfo.campaignType == 6))
      {   
          if((this.createCampaign !== 'false' && this.Campaign.BasicInfo.IRKPIs.includes(0) && (!this.IRProductAvailabilityProductHierarchySelection || this.IRProductAvailabilityProductHierarchySelection.length == 0)) || (this.createCampaign !== 'true' && this.campaignBasicInfo.irkpIs.includes(0) && (!this.IRProductAvailabilityProductHierarchySelection || this.IRProductAvailabilityProductHierarchySelection.length == 0)))
          {
              if(this.createCampaign !== 'false')
              this.snackBarMessage = "Choosing "+ this.campaignProductBasis[this.Campaign.BasicInfo.IRProductAvailabilityBasis] +" is mandatory.";
              else
              this.snackBarMessage = "Choosing "+ this.campaignProductBasis[this.campaignBasicInfo.irProductAvailabilityBasis] +" is mandatory.";

              this.snackbar = true;
              return;
          }
          if((this.createCampaign !== 'false' && this.Campaign.BasicInfo.IRKPIs.includes(1) && (!this.IRSOSProductHierarchySelection || this.IRSOSProductHierarchySelection.length == 0)) || (this.createCampaign !== 'true' && this.campaignBasicInfo.irkpIs.includes(1) && (!this.IRSOSProductHierarchySelection || this.IRSOSProductHierarchySelection.length == 0)))
          {
            if(this.createCampaign !== 'false')
            this.snackBarMessage = "Choosing "+ this.campaignProductBasis[this.Campaign.BasicInfo.IRSOSBasis] +" is mandatory.";
            else
            this.snackBarMessage = "Choosing "+ this.campaignProductBasis[this.campaignBasicInfo.irsosBasis] +" is mandatory.";

            this.snackbar = true;
            return;
          }
      }
      else{
        this.snackBarMessage = "Choosing "+ this.campaignProductBasis[this.ProductBasis] +" is mandatory.";
        this.snackbar = true;
        return;
      }
    }

    return true
  }
  
  private campaignConfig: ICampaignConfig = {
     geoType: "",
     geoValue: [],
     chains:[],
     outlets: [],
     productBasis: null,
     productBasisValues: [],
     IRProductAvailabilityBasis:null,
     IRProductAvailabilityBasisValues:[],
     IRSosBasis:null,
     IRSosBasisValues:[],
   };
   private Config: ICampaignConfig = {
     geoType: "",
     geoValue: [],
     chains:[],
     outlets: [],
     productBasis: null,
     productBasisValues: [],
     IRProductAvailabilityBasis:null,
     IRProductAvailabilityBasisValues:[],
     IRSosBasis:null,
     IRSosBasisValues:[],
   };

  public GetStepData(){
      if(this.editCampaign=='true')
      {
        this.campaignConfig.geoType = this.geoTypeId;
      }
      if(this.createCampaign=='true')
      {
        this.campaignConfig.geoType = this.campaignConfig.geoType;
      }
      this.campaignConfig.geoValue = this.campaignConfig.geoValue;
      
      if(this.editCampaign=='true')
      {
        this.campaignConfig.chains = this.Config.chains;
      }
      if(this.createCampaign=='true')
      {
        this.campaignConfig.chains = this.campaignConfig.chains;
      }
      if(this.editCampaign=='true')
      {
        this.campaignConfig.outlets = this.Config.outlets;
      }
      if(this.createCampaign=='true')
      {
        this.campaignConfig.outlets = this.campaignConfig.outlets;
      }
      if(this.createCampaign=='true')
      {
        if(this.Campaign.BasicInfo.CampaignType != 6)
        {
          this.campaignConfig.productBasis = this.ProductBasis;
          this.campaignConfig.productBasisValues = this.productHierarchySelection;
        }
        else if(this.Campaign.BasicInfo.CampaignType == 6)
        {
          if(this.Campaign.BasicInfo.IRKPIs.includes(0))
          {
            this.campaignConfig.IRProductAvailabilityBasis = this.Campaign.BasicInfo.IRProductAvailabilityBasis;
            this.campaignConfig.IRProductAvailabilityBasisValues = this.IRProductAvailabilityProductHierarchySelection;
          }
          if(this.Campaign.BasicInfo.IRKPIs.includes(1))
          {
            this.campaignConfig.IRSosBasis = this.Campaign.BasicInfo.IRSOSBasis;
            this.campaignConfig.IRSosBasisValues = this.IRSOSProductHierarchySelection;
          }
        }
      }
      if(this.editCampaign=='true')
      {
        if(this.CampaignBasicInfo.campaignType != 6)
        {
          this.campaignConfig.productBasis = this.CampaignBasicInfo.campaignBasis;
          this.campaignConfig.productBasisValues = this.productHierarchySelection;
        }
        else if(this.CampaignBasicInfo.campaignType == 6)
        {
          if(this.CampaignBasicInfo.irkpIs.includes(0))
          {
            this.campaignConfig.IRProductAvailabilityBasis = this.CampaignBasicInfo.irProductAvailabilityBasis;
            this.campaignConfig.IRProductAvailabilityBasisValues = this.IRProductAvailabilityProductHierarchySelection;
          }
          if(this.CampaignBasicInfo.irkpIs.includes(1))
          {
            this.campaignConfig.IRSosBasis = this.CampaignBasicInfo.irsosBasis;
            this.campaignConfig.IRSosBasisValues = this.IRSOSProductHierarchySelection;
          }
        }
      }
      return this.campaignConfig;
  }

  //public xyz:any=[];
  public GetDataForStep4(){
    var response : {[k: string]: any} = {};
    response.geoTypeId = this.campaignConfig.geoType;
    response.geoTypeText = this.geoLevels[this.campaignConfig.geoType];
    response.geoValuesId = this.campaignConfig.geoValue.map(s=> this.geoSelectionValues.find((t:any)=>t.id==s).id);
    response.geoValuesText = this.campaignConfig.geoValue.map(s=> this.geoSelectionValues.find((t:any)=>t.id==s).name);
    if(this.createCampaign=='true'){
      response.chains = this.campaignConfig.chains.map(s=> this.chainValues.find((t:any)=>t.id==s).name);
    }
    if(this.editCampaign=='true'){
      response.chains = this.Config.chains.map(s=> this.chainValues.find((t:any)=>t.id==s).name);
    }
    if(this.createCampaign=='true'){
      response.outlets = this.campaignConfig.outlets.map(s=> this.outletValues.find((t:any)=>t.id==s).shopName);
    }
    else if(this.editCampaign=='true'){
      response.outlets = this.Config.outlets.map(s=> this.outletvals.find((t:any)=>t.id==s).shopName);
    }
    if(this.createCampaign=='true'){
      if(this.Campaign.BasicInfo.CampaignType != 6)
        {
          response.productBasis = this.campaignProductBasis[this.ProductBasis];
        }
        else if(this.Campaign.BasicInfo.CampaignType == 6)
        {
          if(this.Campaign.BasicInfo.IRKPIs.includes(0))
          {
            response.IRProductAvailabilityBasis = this.campaignProductBasis[this.Campaign.BasicInfo.IRProductAvailabilityBasis]
          }
          if(this.Campaign.BasicInfo.IRKPIs.includes(1))
          {
            response.IRSosBasis = this.campaignProductBasis[this.Campaign.BasicInfo.IRSOSBasis]
          }
        }
    }
    else if(this.editCampaign=='true'){
      if(this.CampaignBasicInfo.campaignType != 6)
        {
          response.productBasis = this.campaignProductBasis[this.Config.productBasis];
        }
        else if(this.CampaignBasicInfo.campaignType == 6)
        {
          if(this.CampaignBasicInfo.irkpIs.includes(0))
          {
            response.IRProductAvailabilityBasis = this.campaignProductBasis[this.CampaignBasicInfo.irProductAvailabilityBasis]
          }
          if(this.CampaignBasicInfo.irkpIs.includes(1))
          {
            response.IRSosBasis = this.campaignProductBasis[this.CampaignBasicInfo.irsosBasis]
          }
        }
    }
    if(this.createCampaign !== 'false')
    {
        if(this.ProductBasis !=  CampaignBasis.Outlet && this.Campaign.BasicInfo.CampaignType != 6)
        {
          // @ts-ignore
          response.productBasisValues = this.$refs.cambasis.GetDataForStep4();
        } 
        else
        {
          response.productBasisValues = [];
        }
        if(this.Campaign.BasicInfo.CampaignType == 6 && this.Campaign.BasicInfo.IRKPIs.includes(0))
        {
          // @ts-ignore
          response.productAvailabilityBasisValues = this.$refs.prodAvailCampBasis.GetDataForStep4();
        }
        if(this.Campaign.BasicInfo.CampaignType == 6 && this.Campaign.BasicInfo.IRKPIs.includes(1))
        {
          // @ts-ignore
          response.SOSBasisValues = this.$refs.SOSCampBasis.GetDataForStep4();
        }
  } 
  else
  {
    if(this.CampaignBasicInfo.campaignBasis !=  CampaignBasis.Outlet && this.CampaignBasicInfo.campaignType != 6)
        {
          // @ts-ignore
          response.productBasisValues = this.$refs.cambasis.GetDataForStep4();
        } 
        else
        {
          response.productBasisValues = [];
        }
        if(this.CampaignBasicInfo.campaignType == 6 && this.CampaignBasicInfo.irkpIs.includes(0))
        {
          // @ts-ignore
          response.productAvailabilityBasisValues = this.$refs.prodAvailCampBasis.GetDataForStep4();
        }
        if(this.CampaignBasicInfo.campaignType == 6 && this.CampaignBasicInfo.irkpIs.includes(1))
        {
          // @ts-ignore
          response.SOSBasisValues = this.$refs.SOSCampBasis.GetDataForStep4();
        }
  }
   
    
    //this.xyz=response
    return response;
  }

  closeSnackbar(value: boolean) {
    this.snackbar = value;
  }

  private snackBarMessage: string = "";
  private snackbar: boolean = false;
  // private hideDropdown() {
  //   this.disableChainDropdown = false;
  //   this.disableShopDropdown = true;
  // }
  private toggleSideDrawer(value: boolean) {
    this.sideDrawer = value;
  }

  private getGeoValues(geoLevel: string) {
    let that = this;
    if (!geoLevel) {
      that.geoSelectionValues = {}
    }

    GeographyService.getAllGeographiesOfType(geoLevel)
      .then((res:any) => {
        that.geoSelectionValues = res.data;
      })
      .catch((err:any) => {
        console.log(err);
      });

  }

  @Watch("geoTypeId")
  async UpdatedGeo(geoLevel: string) {
    let that = this;
    if (!geoLevel) {
      that.geoSelectionValues = {}
    }

    GeographyService.getAllGeographiesOfType(geoLevel)
      .then((res:any) => {
        that.geoSelectionValues = res.data;
      })
      .catch((err:any) => {
        console.log(err);
      });

  }

  private getChainValues() {
    let that = this;
    that.chainValues = {};
      
    ChainService.getChain_List()
        .then((res:any) => {
        that.chainValues = res.data;
      })
      .catch((err:any) => {
        console.log(err);
      });
  }
  
  private getOutletValues(geoLevelIds: Array<number>) {
    //console.log(this.campaignConfig.geoType);
    // console.log(geoLevelIds);
    
      let that = this;
      this.dummy.pop();
      this.dummy.push(geoLevelIds.map(s=> this.geoSelectionValues.find((t:any)=>t.id==s).name));
      //this.visibility == true;
      if (!geoLevelIds) {
        that.outletValues = {};
      }

      GeographyService.GetOutletsForGeoType(this.campaignConfig.geoType, geoLevelIds)
      .then((res:any) => {
        that.outletValues = res.data;
      })
      .catch((err:any) => {
        console.log(err);
      });
  }

  private SelectedOutletsFromSideDrawer(valArray: Array<any>) {
    this.sideDrawer = false;
    valArray.forEach((e) => {
      // @ts-ignore
      if (!this.campaignConfig.outlets.find((f) => f == e.id)) {
        this.campaignConfig.outlets.push(e.id);
      }
    });
  }

  RemoveGeoChip(index: number) {
    this.campaignConfig.geoValue.splice(index, 1);
    this.dummy.splice(index,1);
    this.getOutletValues(this.campaignConfig.geoValue);
  }

  RemoveOutletChip(index: number) {
    this.campaignConfig.outlets.splice(index, 1);
    this.getOutletValues(this.campaignConfig.geoValue);
  }

  RemoveOutletChip1(index: number) {
    this.Config.outlets.splice(index, 1);
    this.outletfn;
  }

  RemoveChainChip(index: number) {
    this.campaignConfig.chains.splice(index, 1);
    this.getChainValues();
  }

  RemoveChainChip1(index: number) {
    this.Config.chains.splice(index, 1);
    this.getChainValues();
  }

  private GetGeoValueObjArray() {
    let that = this;
    // @ts-ignore
    let arr = [];
    // @ts-ignore
    that.campaignConfig.geoValue.forEach((element) => {
      // @ts-ignore
      arr.push(that.geoSelectionValues.find((e) => e.id == element));
    });
    // @ts-ignore
    return arr;
  }
  
  @Watch("campaignConfig.geoType")
  async GeoTypeChange() {
    this.campaignConfig.geoValue = []
  }

@Watch("campaignConfig.chains")
  async UpdatedProductBasis(ChainIds: Array<number>) {
    let that = this;
    const getResult =   await CompanySettingService.companyUsesChainSKUMapping()
    if (this.campaignProductBasis[this.ProductBasis] == "Products" &&  getResult.data.result == true) {
      ChainService.GetSkusForChain(ChainIds)
         .then((res) => {
           that.items = res.data;
         })
         .catch((err) => {
           console.log(err);
         });
     }
     else if (this.campaignProductBasis[this.ProductBasis] == "Primary Category") {
      ProductService.getAllPC()
        .then((res) => {
          that.items = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (this.campaignProductBasis[this.ProductBasis] == "Secondary Category") {
      ProductService.getAllSC()
        .then((res) => {
          that.items = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }else if (this.campaignProductBasis[this.ProductBasis] == "Products"){
      ProductService.getAllProduct({})
        .then((res) => {
          that.items = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  @Watch("Config.chains")
  async UpdatedProductBasis1(ChainIds: Array<number>) {
    let that = this;
    const getResult =   await CompanySettingService.companyUsesChainSKUMapping()
    if(that.CampaignBasicInfo.campaignType != 6)
    {
          if (this.campaignProductBasis[this.Config.productBasis] == "Products" &&  getResult.data.result == true) {
            ChainService.GetSkusForChain(ChainIds)
                .then((res) => {
                  that.items1 = res.data;
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            else if (this.campaignProductBasis[this.Config.productBasis] == "Primary Category") {
            ProductService.getAllPC()
              .then((res) => {
                that.items1 = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (this.campaignProductBasis[this.Config.productBasis] == "Secondary Category") {
            ProductService.getAllSC()
              .then((res) => {
                that.items1 = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
          }else if (this.campaignProductBasis[this.Config.productBasis] == "Products"){
            ProductService.getAllProduct({})
              .then((res) => {
                that.items1 = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
          }
    } else if (that.CampaignBasicInfo.campaignType == 6)
    {
      if(that.CampaignBasicInfo.irkpIs.includes(0))
      {
        if (this.campaignProductBasis[this.CampaignBasicInfo.irProductAvailabilityBasis] == "Products" &&  getResult.data.result == true) {
            ChainService.GetSkusForChain(ChainIds)
                .then((res) => {
                  that.irProductAvailabilityBasisItems1 = res.data;
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            else if (this.campaignProductBasis[this.CampaignBasicInfo.irProductAvailabilityBasis] == "Primary Category") {
            ProductService.getAllPC()
              .then((res) => {
                that.irProductAvailabilityBasisItems1 = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (this.campaignProductBasis[this.CampaignBasicInfo.irProductAvailabilityBasis] == "Secondary Category") {
            ProductService.getAllSC()
              .then((res) => {
                that.irProductAvailabilityBasisItems1 = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
          }else if (this.campaignProductBasis[this.CampaignBasicInfo.irProductAvailabilityBasis] == "Products"){
            ProductService.getAllProduct({})
              .then((res) => {
                that.irProductAvailabilityBasisItems1 = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
          }
      }
      if(that.CampaignBasicInfo.irkpIs.includes(1))
      {
        if (this.campaignProductBasis[this.Config.IRSosBasis] == "Products" &&  getResult.data.result == true) {
            ChainService.GetSkusForChain(ChainIds)
                .then((res) => {
                  that.sosBasisItems1 = res.data;
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            else if (this.campaignProductBasis[this.Config.productBasis] == "Primary Category") {
            ProductService.getAllPC()
              .then((res) => {
                that.sosBasisItems1 = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (this.campaignProductBasis[this.Config.productBasis] == "Secondary Category") {
            ProductService.getAllSC()
              .then((res) => {
                that.sosBasisItems1 = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
          }else if (this.campaignProductBasis[this.Config.productBasis] == "Products"){
            ProductService.getAllProduct({})
              .then((res) => {
                that.sosBasisItems1 = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
          }
      }
      
     
    }
  }
  
  async mounted() {
    let that = this;
    GeographyService.GetAllHierarchyLevel()
      .then((res:any) => {
        that.geoLevels = {};
        // @ts-ignore
        res.data.forEach((element) => {
          that.geoLevels[element.id] = element.name;
        });
      })
      .catch((err:any) => {
        console.log(err);
      });
      this.getChainValues();
    const res = await CampaignService.getCampaignInfo(this.$route.params.ID)
    this.campaignBasicInfo=res.data.campaignModel.basicInfo;
    this.campaignConfigDisplay=res.data.campaignConfigDisplay;

    this.Config.chains= (this.campaignConfigDisplay.chainids);
    this.Config.outlets= (this.campaignConfigDisplay.outletids);

    this.Config.productBasis =this.campaignBasicInfo.campaignBasis;
    }
}
